/* eslint-disable no-console */
import { DOCUMENT, Location } from '@angular/common';
import { inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';
import { EMPTY, from } from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { DialogUndercutScreenLimitComponent } from '@priva/components-candidates/app-screen-size-detector';
import { PrivaNotificationsService } from '@priva/components/notifications';
import { EstimateProgressService } from '@priva/components/progress-overlay';
import { PrivaLocalizationService } from '@priva/localization';

import { ErrorDetail, ErrorHandlingService, getErrors, getUserNotification } from 'app/common/error-handling';
import { AppConfigurationService } from 'app/configuration';
import { FeedbackComponent } from 'app/dialogs/feedback/feedback.component';
import { WhatsNewComponent } from 'app/dialogs/whats-new';

import { AppActions, AppStateContainer } from '.';
import { PlatformService } from '../common/platform.service';

@Injectable({
    providedIn: 'root',
})
export class AppEffects {
    private document: Document = inject(DOCUMENT);
    private actions$: Actions = inject(Actions);
    private store$: Store<AppStateContainer> = inject(Store);
    private router: Router = inject(Router);
    private notificationsService: PrivaNotificationsService = inject(PrivaNotificationsService);
    private errorService: ErrorHandlingService = inject(ErrorHandlingService);
    private oauthService: OAuthService = inject(OAuthService);
    private notificationService: PrivaNotificationsService = inject(PrivaNotificationsService);
    private localizationService: PrivaLocalizationService = inject(PrivaLocalizationService);
    private estimateProgressService: EstimateProgressService = inject(EstimateProgressService);
    private platformService: PlatformService = inject(PlatformService);
    private appConfigurationService: AppConfigurationService = inject(AppConfigurationService);
    private location: Location = inject(Location);
    private zone: NgZone = inject(NgZone);

    constructor() {
        window.addEventListener('offline', () =>
            this.zone.run(() => this.store$.dispatch(AppActions.goOffline())),
        );
        window.addEventListener('online', () =>
            this.zone.run(() => this.store$.dispatch(AppActions.checkOnline())),
        );
    }

    public routerNavigated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filter(
                (action: RouterNavigatedAction) =>
                    action.payload.event.url === '/' ||
                    action.payload.event.url.startsWith('/?') ||
                    action.payload.event.url === '/solutions',
            ),
            map((action) => {
                // only show solutions dialog when navigate to /solutions (without solution)
                const showSolutions = action.payload.event.urlAfterRedirects === '/solutions';
                return AppActions.resetAppState({ showSolutions });
            }),
        ),
    );

    public initializeAppAfterFirstNavigated$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filter((action: RouterNavigatedAction) => !action.payload.routerState.url.startsWith('/error')),
            filter((action: RouterNavigatedAction) =>
                action.payload.routerState.url.startsWith('/solutions'),
            ),
            withLatestFrom(this.store$),
            filter(([_, state]) => !!state.app && !state.app.initialized),
            map(([_action, _state]) => AppActions.initializeApp()),
        );
    });

    public initializeApp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.initializeApp),
            switchMap(function* () {
                yield AppActions.checkOnline();
                yield AppActions.initializeAppSuccess();
            }),
        ),
    );

    public navigateToSolutionsWhenOffline$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppActions.initializeAppSuccess),
                switchMap(() => from(this.platformService.isOnline())),
                filter((isOnline) => !isOnline),
                tap(() => {
                    this.router.navigate(['solutions']);
                }),
            ),
        { dispatch: false },
    );

    public checkOnlineAfterReset$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.resetAppState),
            withLatestFrom(this.store$),
            filter(([_action, state]) => state.app.initialized),
            map(() => AppActions.checkOnline()),
        ),
    );

    public whatsNew$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.whatsNew),
            map(() => {
                const dialog = {
                    component: WhatsNewComponent,
                };
                return AppActions.openDialog({ dialog });
            }),
        ),
    );

    public signOut$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppActions.signOut),
                map(() => {
                    this.router.navigate(['signout']);
                }),
            ),
        { dispatch: false },
    );

    public provideFeedback$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppActions.provideFeedback),
                map(() => {
                    alert('Not implemented yet!');
                }),
            ),
        { dispatch: false },
    );

    public screenMinWidthUndershot$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.screenMinimumWidthUndershot),
            map((action) => {
                if (action.viewport.matches) {
                    return AppActions.closeDialog({});
                } else {
                    const solutionModalOverlay = Array.from(
                        this.document.getElementsByTagName('modal-overlay'),
                    );
                    if (solutionModalOverlay) {
                        solutionModalOverlay.forEach((x: HTMLElement) => (x.style.visibility = 'hidden'));
                    }
                    return AppActions.openDialog({
                        dialog: {
                            component: DialogUndercutScreenLimitComponent,
                            inputs: {
                                width: action.viewport.width,
                            },
                        },
                    });
                }
            }),
        ),
    );

    public apiError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.apiError),
            switchMap((action) => {
                const actions = [];
                const errors = getErrors(action.error, action.resourceKey);
                if (action.isDialogNotification && errors.length) {
                    actions.push(
                        AppActions.updateDialogNotification({
                            notification: getUserNotification(errors[0]),
                        }),
                    );
                } else {
                    errors.forEach((error: ErrorDetail) =>
                        actions.push(AppActions.showNotification({ error: error })),
                    );
                }
                return actions.length === 0 ? EMPTY : actions;
            }),
        ),
    );

    public showToaster$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppActions.showError),
                tap((action) => {
                    const title = this.localizationService.instant(action.error.title);
                    this.notificationService.toaster.error(title, {
                        timeout: 0,
                        actions: [
                            {
                                id: 'reload',
                                text: 'Reload',
                                importance: 'tertiary',
                                execute: () => {
                                    window.location.href = '/solutions';
                                },
                            },
                        ],
                    });
                }),
            ),
        { dispatch: false },
    );

    public showLoader$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppActions.showLoader),
                tap((action) => this.estimateProgressService.start(action.id, action.steps)),
            ),
        { dispatch: false },
    );

    public updateLoader$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppActions.updateLoader),
                tap((action) => this.estimateProgressService.update(action.id, action.currentStep)),
            ),
        { dispatch: false },
    );

    public hideLoader$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppActions.clearLoader),
                tap((action) => this.estimateProgressService.end(action.id)),
            ),
        { dispatch: false },
    );

    public checkOnlineStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.checkOnline),
            withLatestFrom(this.store$),
            switchMap(async ([, state]) => {
                const isOnline = await this.platformService.isOnline();
                return { state, isOnline };
            }),
            switchMap(function* ({ state, isOnline }) {
                if (isOnline !== state.app.online) {
                    yield isOnline ? AppActions.goOnline() : AppActions.goOffline();
                }
            }),
        ),
    );

    public stopTokenRefresh$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppActions.goOffline),
                withLatestFrom(this.store$),
                filter(([, state]) => state.app.online),
                tap(() => this.oauthService.stopAutomaticRefresh()),
            ),
        { dispatch: false },
    );

    public startTokenRefresh$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppActions.goOnline),
                withLatestFrom(this.store$),
                filter(([, state]) => !state.app.online),
                map(async () => {
                    if (this.appConfigurationService.configuration.authentication?.authConfig) {
                        this.oauthService.configure(
                            this.appConfigurationService.configuration.authentication.authConfig,
                        );
                        await this.oauthService.loadDiscoveryDocumentAndLogin({
                            state: btoa(this.location.path()),
                        });
                    }
                    this.oauthService.setupAutomaticSilentRefresh();
                }),
            ),
        { dispatch: false },
    );

    public showNotification$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppActions.showNotification),
                tap((action) => {
                    const message = this.errorService.errorToMessage(action.error);

                    // 405 Method Not Allowed, endpoint exists but not implemented yet
                    if (action.error.status === 405) {
                        this.store$.dispatch(AppActions.dialogNotImplementedYet());
                    }

                    switch (action.error.type) {
                        case 'info':
                            this.notificationsService.toaster.info(message, { timeout: 4000 });
                            break;
                        case 'success':
                            this.notificationsService.toaster.success(message, { timeout: 4000 });
                            break;
                        case 'error':
                            console.error(action.error);
                            this.notificationsService.toaster.error(message);
                            break;
                        case 'warning':
                            console.warn(action.error);
                            this.notificationsService.toaster.warning(message);
                            break;
                        case 'logging':
                            // TvR: not used for now only log redux action
                            this.notificationsService.toaster.info(message, { timeout: 4000 });
                            break;
                        case 'fatal':
                            console.error(action.error);
                            this.notificationsService.bar.error(message);
                            break;
                        default:
                            this.notificationsService.toaster.error(message);
                            break;
                    }
                }),
            ),
        { dispatch: false },
    );

    public clearAllThrobbers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                AppActions.apiError,
                AppActions.clearDialogNotification,
                AppActions.updateDialogNotification,
            ),
            withLatestFrom(this.store$),
            filter(([_action, state]) => JSON.stringify(state.app.throbbers) !== JSON.stringify({})),
            map(() => AppActions.clearAllThrobbers()),
        ),
    );

    public clearDialogNotification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.closeDialog, AppActions.openDialog),
            withLatestFrom(this.store$),
            filter(([_action, state]) => !!state.app.dialogNotification),
            map(() => AppActions.clearDialogNotification()),
        ),
    );

    public clearGlobalErrors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.closeDialog, AppActions.openDialog),
            withLatestFrom(this.store$),
            filter(([_action, state]) => !!state.app.error),
            map(() => AppActions.clearApiErrorGlobal()),
        ),
    );

    public dialogNotImplementedYet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.dialogNotImplementedYet),
            map(() => {
                const dialog = {
                    component: FeedbackComponent,
                    inputs: {
                        titleKey: 'APP.DIALOG_NOT_IMPLEMENTED_YET.TITLE',
                        textKey: 'APP.DIALOG_NOT_IMPLEMENTED_YET.SUBTITLE',
                    },
                };
                return AppActions.openDialog({ dialog });
            }),
        ),
    );
}
