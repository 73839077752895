import { AfterContentChecked, ChangeDetectorRef, Component, inject, OnInit, Signal } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';

import { AppShellComponentType } from '@priva/appshell';
import { DynamicComponent } from '@priva/components/dynamic';

import { SolutionsActions } from './solutions/state';
import { AppStateContainer } from './state';

@Component({
    selector: 'app-main',
    templateUrl: './app-main.component.html',
    styleUrl: 'app-main.component.scss',
})
export class AppMainComponent implements AfterContentChecked, OnInit {
    private readonly store$ = inject<Store<AppStateContainer>>(Store<AppStateContainer>);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly oauthService = inject(OAuthService);
    private readonly router = inject(Router);
    public activeDialogs: Signal<DynamicComponent[]>;
    public activePanel: Signal<DynamicComponent | undefined>;
    public httpDelay: Signal<boolean>;

    /* istanbul ignore next, just dispatch action */
    constructor() {
        this.activeDialogs = this.store$.selectSignal((s) => s.app.activeDialogs);
        this.activePanel = this.store$.selectSignal((s) => s.app.activePanel);
        this.httpDelay = this.store$.selectSignal((s) => s.app.httpDelay);
    }

    public async ngOnInit() {
        // Deeplink after the login succeeded.
        if (this.oauthService.state && this.oauthService.state !== '') {
            await this.router.navigateByUrl(atob(decodeURIComponent(this.oauthService.state)));
        }
    }

    public ngAfterContentChecked(): void {
        // fix ExpressionChangedAfterItHasBeenCheckedError in @for
        this.changeDetectorRef.detectChanges();
    }

    public async clickSolutionSwitcher(type: AppShellComponentType) {
        if (type === AppShellComponentType.SolutionSwitcher) {
            this.store$.dispatch(SolutionsActions.showSolutionsDialog());
        }
    }
}
