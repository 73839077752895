import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { AppActions } from 'app/state';

import { FirmwareActions } from '.';

@Injectable({
    providedIn: 'root',
})
export class ProductEffects {
    private readonly actions$ = inject(Actions);
    public getLatestFirmware$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.initializeAppSuccess),
            map(() =>
                FirmwareActions.getLatestFirmwares({ product: 'ZoneController', filter: { latest: true } }),
            ),
        ),
    );
}
