import { Action, createReducer, on } from '@ngrx/store';

import { DynamicComponent } from '@priva/components/dynamic';

import { AppActions } from '.';
import { AppState, initialAppState } from './app.state';

const reducer = createReducer<AppState>(
    initialAppState,

    on(AppActions.resetAppState, (state) => ({
        ...initialAppState,
        initialized: state.initialized,
        online: state.online,
    })),

    on(AppActions.initializeAppSuccess, (state, _action): AppState => {
        return {
            ...state,
            initialized: true,
        };
    }),

    on(AppActions.apiErrorGlobal, (state, action): AppState => {
        if (action.error) {
            return {
                ...state,
                error: action.error,
                throbbers: {},
            };
        } else {
            return state;
        }
    }),

    on(AppActions.clearApiErrorGlobal, (state, _action): AppState => {
        return {
            ...state,
            error: undefined,
        };
    }),

    on(AppActions.openDialog, (state, action): AppState => {
        let activeDialogs;
        if (action.closeOthers) {
            activeDialogs = [action.dialog];
        } else {
            activeDialogs = [
                ...state.activeDialogs.filter((d) => d.component !== action.dialog.component),
                action.dialog,
            ];
        }
        return { ...state, activeDialogs };
    }),

    on(AppActions.closeDialog, (state, action): AppState => {
        let activeDialogs: DynamicComponent[];
        if (action.closeOthers) {
            activeDialogs = [];
        } else if (action.component) {
            activeDialogs = state.activeDialogs.filter(
                (dialog: DynamicComponent) => action.component !== dialog.component,
            );
        } else {
            activeDialogs = [...state.activeDialogs];
            activeDialogs.pop();
        }
        return { ...state, activeDialogs };
    }),

    on(AppActions.openPanel, (state, action): AppState => {
        return {
            ...state,
            activePanel: action.panel,
        };
    }),

    on(AppActions.closePanel, (state, _action): AppState => {
        return {
            ...state,
            activePanel: undefined,
        };
    }),

    on(AppActions.goOnline, (state, _action): AppState => {
        if (!state.online) {
            return {
                ...state,
                online: true,
            };
        } else {
            return state;
        }
    }),

    on(AppActions.goOffline, (state, _action): AppState => {
        if (state.online) {
            return {
                ...state,
                online: false,
            };
        } else {
            return state;
        }
    }),

    on(AppActions.showThrobber, (state, action): AppState => {
        const throbbers = { ...state.throbbers };
        throbbers[action.selectorClass] = action.loadingMessage || ' ';
        return {
            ...state,
            throbbers,
        };
    }),

    on(AppActions.clearThrobber, (state, action): AppState => {
        const throbbers = { ...state.throbbers };
        delete throbbers[action.selectorClass];
        return {
            ...state,
            throbbers,
        };
    }),

    on(AppActions.clearAllThrobbers, (state, _action): AppState => {
        return {
            ...state,
            throbbers: {},
        };
    }),

    on(AppActions.setAutoNamingFormula, (state, action): AppState => {
        const autoNamingFormulas = { ...state.autoNamingFormulas };
        autoNamingFormulas[action.autoNamingKey] = action.context;
        return {
            ...state,
            autoNamingFormulas,
        };
    }),

    on(AppActions.setAutoNamingCodes, (state, action): AppState => {
        return {
            ...state,
            autoNamingCodes: { ...action.autoNamingCodes },
        };
    }),

    on(AppActions.setAutoNamingCode, (state, action): AppState => {
        const autoNamingCodes = { ...state.autoNamingCodes };
        autoNamingCodes[action.autoNamingKey].code = action.code;
        return {
            ...state,
            autoNamingCodes,
        };
    }),

    on(AppActions.screenMinimumWidthUndershot, (state, action): AppState => {
        return {
            ...state,
            viewport: action.viewport,
        };
    }),

    on(AppActions.updateDialogNotification, (state, action): AppState => {
        return {
            ...state,
            dialogNotification: action.notification,
        };
    }),

    on(AppActions.clearDialogNotification, (state): AppState => {
        return {
            ...state,
            dialogNotification: undefined,
        };
    }),

    on(AppActions.showHttpDelay, (state): AppState => {
        return {
            ...state,
            httpDelay: true,
        };
    }),

    on(AppActions.clearHttpDelay, (state): AppState => {
        return {
            ...state,
            httpDelay: false,
        };
    }),

    on(AppActions.setApplicationMode, (state, action): AppState => {
        return {
            ...state,
            applicationMode: action.mode,
        };
    }),
);

export function appReducer(state: AppState | undefined, action: Action) {
    return reducer(state, action);
}
