import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { Datasheet } from '@priva/next-model';

import { ElementsStateContainer } from 'app/solution/elements/state';
import { SolutionStateContainer } from 'app/solution/state';
import { DatasheetApiActions } from 'app/template/datasheet/state';

import { NextLocalStorageService } from '../../local-storage/next-local-storage.service';
import { X_HEADER } from '../offline-sync.helper';

@Injectable()
export class OfflineSyncSearchDeviceDatasheetsInterceptor implements HttpInterceptor {
    private readonly localStorageService = inject(NextLocalStorageService);
    private readonly store$ = inject<Store<SolutionStateContainer & ElementsStateContainer>>(
        Store<SolutionStateContainer & ElementsStateContainer>,
    );

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.headers.get(X_HEADER) === DatasheetApiActions.getAllDatasheets.type) {
            return this.store$.pipe(
                first(),
                switchMap((state) => {
                    if (state.solution?.active?.offline) {
                        return from(this.localStorageService.getDatasheets(state.solution.activeId)).pipe(
                            map((datasheets: Datasheet[]) => new HttpResponse({ body: datasheets })),
                        );
                    }
                    return next.handle(request);
                }),
            );
        }
        return next.handle(request);
    }
}
