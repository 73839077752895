import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'creator/src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { CurrentUser, PrivaAuthCurrentUserService } from '@priva/auth/current-user';
import { PrivaErrorRouteUrls } from '@priva/error-pages';

@Injectable()
export class FeatureGuard {
    private currentUserService = inject(PrivaAuthCurrentUserService);
    private readonly router = inject(Router);
    private hasFeature = new Subject<boolean>();
    constructor() {
        this.currentUserService.user.pipe(take(1)).subscribe((user: CurrentUser) => {
            const hasFeature =
                !environment.configuration['featureGuard'] ||
                user?.features?.some(
                    (feature) =>
                        feature.name === environment.configuration['featureGuard'] && feature.state === true,
                ) ||
                false;
            this.hasFeature.next(hasFeature);
            if (!hasFeature) {
                this.router.navigate([PrivaErrorRouteUrls.AccessDenied, { statuscode: 403 }]);
            }
        });
    }

    public canActivate(): Observable<boolean> {
        return this.hasFeature.asObservable();
    }

    public canActivateChild(): Observable<boolean> {
        return this.hasFeature;
    }
}
