import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { Solution } from '@priva/next-model';

import { SolutionActions } from 'app/solution/state';

import { NextLocalStorageService } from '../../local-storage/next-local-storage.service';
import { extractSolutionIdFromNextApi, X_HEADER } from '../offline-sync.helper';

@Injectable()
export class OfflineSyncSolutionInterceptor implements HttpInterceptor {
    private localStorageService = inject(NextLocalStorageService);

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.headers.get(X_HEADER) !== SolutionActions.getSolution.type) {
            return next.handle(request);
        }
        const solutionId = extractSolutionIdFromNextApi(request.url);

        // Solution is the only entity from which the offline stat should be determined by the local storage itself
        // as a solution is the starting point of retrieving the other entities.
        // Below we set the offline flag, which will be put in the store and make sure other requests for other entities are
        // intercepted
        return from(this.localStorageService.exists(solutionId)).pipe(
            first(),
            switchMap((offline) => {
                if (!offline) {
                    return next.handle(request);
                } else {
                    return from(
                        this.localStorageService.getSolution(extractSolutionIdFromNextApi(request.url)),
                    ).pipe(map((solution: Solution) => new HttpResponse({ body: { ...solution, offline } })));
                }
            }),
        );
    }
}
