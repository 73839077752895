import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppShellConfiguration, AppShellModule, PrimaryNavigationService } from '@priva/appshell';
import { PrivaButtonModule } from '@priva/components/button';
import { PrivaDialogConfirmModule } from '@priva/components/dialog-confirm';
import { PrivaDynamicModule } from '@priva/components/dynamic';
import { PrivaLocalizationModule } from '@priva/localization';

import { AppMainComponent } from './app-main.component';
import { NeXtPrimaryNavigationService } from './common/routing/primary-navigation.service';
import { AppConfigurationService } from './configuration';
import { AboutModule } from './dialogs/about/about.module';
import { FeedbackModule } from './dialogs/feedback/feedback.module';
import { IntroModule } from './dialogs/intro/intro.module';
import { WhatsNewModule } from './dialogs/whats-new';
import { ToInputSignalsPipe } from './to-input-signals.pipe';

function getAppShellConfig(configService: AppConfigurationService): AppShellConfiguration {
    return AppShellConfiguration.from(configService.configuration);
}

@NgModule({
    imports: [
        CommonModule,
        PrivaDynamicModule,
        PrivaButtonModule,
        PrivaLocalizationModule,

        AppShellModule.forRoot(
            {
                provide: AppShellConfiguration,
                useFactory: getAppShellConfig,
                deps: [AppConfigurationService],
            },
            [
                AppShellModule.provideDefaultFeatureToggleRouter(),
                AppShellModule.provideDefaultSolutionService(),
                { provide: PrimaryNavigationService, useClass: NeXtPrimaryNavigationService },
            ],
        ),

        PrivaDialogConfirmModule,
        WhatsNewModule,
        IntroModule,
        AboutModule,
        FeedbackModule,
        ToInputSignalsPipe,
    ],
    declarations: [AppMainComponent],
})
export class AppMainModule {}
