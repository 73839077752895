import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpParams,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { Element } from '@priva/next-model';

import { httpParamsToDeviceFilter } from 'app/common/http';
import { ElementApiActions } from 'app/solution/element/state';
import { ElementsActions } from 'app/solution/elements/state';
import { SolutionState } from 'app/solution/state/solution.state';

import { NextLocalStorageService } from '../../local-storage/next-local-storage.service';
import {
    extractDeviceIdFromFloorplanApi,
    extractSolutionIdFromNextApi,
    extractZoneIdFromSolutionsNextApi,
    X_HEADER,
} from '../offline-sync.helper';

@Injectable()
export class OfflineSyncElementInterceptor implements HttpInterceptor {
    private localStorageService = inject(NextLocalStorageService);
    private readonly store = inject<Store<{ solution: SolutionState }>>(Store<{ solution: SolutionState }>);

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.headers.get(X_HEADER) === ElementApiActions.getElement.type) {
            return this.store.pipe(
                first(),
                switchMap((state) => {
                    if (state.solution?.active?.offline) {
                        return from(this.getElement(request.url)).pipe(map(this.toResponse));
                    } else {
                        return next.handle(request);
                    }
                }),
            );
        }

        if (
            request.headers.get(X_HEADER) === ElementsActions.getAllElementsInSolution.type ||
            request.headers.get(X_HEADER) === ElementsActions.getElementsInZone.type
        ) {
            return this.store.pipe(
                first(),
                switchMap((state) => {
                    if (state.solution?.active?.offline) {
                        return from(this.getElements(request.url, request.params)).pipe(map(this.toResponse));
                    } else {
                        return next.handle(request);
                    }
                }),
            );
        }

        if (request.headers.get(X_HEADER) === ElementsActions.getElementsInZone.type) {
            return this.store.pipe(
                first(),
                switchMap((state) => {
                    if (state.solution?.active?.offline) {
                        return from(this.getZoneElements(request.url)).pipe(map(this.toResponse));
                    } else {
                        return next.handle(request);
                    }
                }),
            );
        }

        return next.handle(request);
    }

    private getElement(url: string): Promise<Element> {
        return this.localStorageService.getElement(
            extractSolutionIdFromNextApi(url),
            extractDeviceIdFromFloorplanApi(url),
        );
    }

    private getElements(url: string, params: HttpParams): Promise<Element[]> {
        return this.localStorageService.getElements(
            extractSolutionIdFromNextApi(url),
            httpParamsToDeviceFilter(params),
        );
    }

    private getZoneElements(url: string): Promise<Element[]> {
        return this.localStorageService.getZoneElements(
            extractSolutionIdFromNextApi(url),
            extractZoneIdFromSolutionsNextApi(url),
        );
    }

    private toResponse(body: any): HttpResponse<any> {
        return new HttpResponse({ body });
    }
}
