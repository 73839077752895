import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { PrivaDialogButton } from '@priva/components/dialog-simple';

import { AppActions, AppState } from 'app/state';

@Component({
    selector: 'app-whats-new',
    templateUrl: './whats-new.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatsNewComponent implements OnInit {
    @HostBinding('class.whats-new')
    public true;

    public buttons: PrivaDialogButton[] = [];

    constructor(private store$: Store<{ app: AppState }>) {}

    public ngOnInit() {
        this.buttons = [{ id: 'ok', importance: 'primary', text: 'GLOBAL.ACTION.CLOSE' }];
    }

    /* istanbul ignore next tvr, just dispatch action */
    public close() {
        this.store$.dispatch(AppActions.closeDialog({}));
    }

    public buttonClick() {
        this.close();
    }
}
