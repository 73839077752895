import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { HardwareRegistration } from '@priva/next-model';

import {
    HardwareRegistrationApiActions,
    HardwareRegistrationStateContainer,
} from 'app/solution/hardware-registration';
import { SolutionStateContainer } from 'app/solution/state';

import { NextLocalStorageService } from '../../local-storage/next-local-storage.service';
import { RequestLocalStorageService } from '../../local-storage/request-local-storage.service';
import { extractElementIdFromNextApi, extractSolutionIdFromNextApi, X_HEADER } from '../offline-sync.helper';

@Injectable()
export class OfflineSyncHardwareRegistrationsInterceptor implements HttpInterceptor {
    private localStorageService = inject(NextLocalStorageService);
    private readonly store = inject<Store<SolutionStateContainer & HardwareRegistrationStateContainer>>(
        Store<SolutionStateContainer & HardwareRegistrationStateContainer>,
    );
    private requestLocalStorageService = inject(RequestLocalStorageService);

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.headers.get(X_HEADER) === HardwareRegistrationApiActions.getHardwareRegistration.type) {
            const solutionId = extractSolutionIdFromNextApi(request.url);
            const elementId = extractElementIdFromNextApi(request.url);
            return this.store.pipe(
                first(),
                switchMap((state) => {
                    if (state.solution?.active?.offline) {
                        return from(
                            this.localStorageService.getHardwareRegistration(solutionId, elementId),
                        ).pipe(
                            map(
                                (hardwareRegistration: HardwareRegistration) =>
                                    new HttpResponse({ body: hardwareRegistration }),
                            ),
                        );
                    } else {
                        return next.handle(request);
                    }
                }),
            );
        }

        if (request.headers.get(X_HEADER) === HardwareRegistrationApiActions.getHardwareRegistrations.type) {
            const solutionId = extractSolutionIdFromNextApi(request.url);
            return this.store.pipe(
                first(),
                switchMap((state) => {
                    if (state.solution?.active?.offline) {
                        return from(this.localStorageService.getHardwareRegistrations(solutionId)).pipe(
                            map(
                                (hardwareRegistrations: HardwareRegistration[]) =>
                                    new HttpResponse({ body: hardwareRegistrations }),
                            ),
                        );
                    } else {
                        return next.handle(request);
                    }
                }),
            );
        }

        if (request.headers.get(X_HEADER) === HardwareRegistrationApiActions.registerElement.type) {
            const solutionId = extractSolutionIdFromNextApi(request.url);
            const elementId = extractElementIdFromNextApi(request.url);
            const body = request.body as HardwareRegistration;
            return this.store.pipe(
                first(),
                switchMap((state) => {
                    if (state.solution?.active?.offline) {
                        return from(
                            this.localStorageService.putHardwareRegistrations(solutionId, [
                                {
                                    elementId,
                                    registrationStatus: 'Registered',
                                    serialNumber: body.serialNumber,
                                    note: body.note,
                                },
                            ]),
                        ).pipe(
                            switchMap(() => from(this.requestLocalStorageService.add(solutionId, request))),
                            map(() => new HttpResponse()),
                        );
                    } else {
                        return next.handle(request);
                    }
                }),
            );
        }

        if (request.headers.get(X_HEADER) === HardwareRegistrationApiActions.unregisterElement.type) {
            const solutionId = extractSolutionIdFromNextApi(request.url);
            const elementId = extractElementIdFromNextApi(request.url);

            return this.store.pipe(
                first(),
                switchMap((state) => {
                    if (state.solution?.active?.offline) {
                        return from(
                            this.localStorageService.deleteHardwareRegistration(solutionId, elementId),
                        ).pipe(
                            switchMap(() => from(this.requestLocalStorageService.add(solutionId, request))),
                            map(() => new HttpResponse()),
                        );
                    } else {
                        return next.handle(request);
                    }
                }),
            );
        }

        return next.handle(request);
    }
}
