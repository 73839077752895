import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { TerminalUnitModel } from '@priva/next-model';

import { LibraryApiActions } from 'app/model-library/state';
import { SolutionState } from 'app/solution/state';

import { NextLocalStorageService } from '../../local-storage/next-local-storage.service';
import { X_HEADER } from '../offline-sync.helper';

@Injectable()
export class OfflineSyncModelInterceptor implements HttpInterceptor {
    private readonly store = inject<Store<{ solution: SolutionState }>>(Store<{ solution: SolutionState }>);
    private localStorageService = inject(NextLocalStorageService);

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.headers.get(X_HEADER) === LibraryApiActions.getAllTerminalUnitModels.type) {
            return this.store.pipe(
                first(),
                switchMap((state) => {
                    const offline = state.solution?.active?.offline;
                    if (offline) {
                        return from(this.localStorageService.getTerminalUnitModels()).pipe(
                            map((models: TerminalUnitModel[]) => new HttpResponse({ body: models })),
                        );
                    } else {
                        return next.handle(request);
                    }
                }),
            );
        }
        return next.handle(request);
    }
}
